var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "user_history",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "item-id": "id",
      "configurable": false,
      "persist-settings": false,
      "selectable": false
    }
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };