import HistoryTable from "@/components/User/HistoryTable.vue";
export default {
  meta: {
    title: "История запросов"
  },
  components: {
    HistoryTable
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  }
};